import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../layouts/index";
import Seo from "../../components/Seo";
import IntroBanner from "../../components/IntroBannerRevamp";
import Banner from "../../images/services/services-banner.jpg";

import Img from "gatsby-image";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import icon from "../../images/icon.svg";

//Images
import lgset from "../../images/logo-set.jpg";
import lgset2 from "../../images/logo-set-2.jpg";
import lgset3 from "../../images/logo-set-3.jpg";
import lgset4 from "../../images/logo-set-4.jpg";
import lgset5 from "../../images/logo-set-5.jpg";
import ic1 from "../../images/device.png";
import ic2 from "../../images/mobile.png";
import ic3 from "../../images/iot.png";
import ic4 from "../../images/data.png";
import comment from "../../images/comment.png";
import StrategyDesign from "../../images/strategy-and-design.jpg";
import BuildEngineer from "../../images/build-and-engineer.jpg";
import ServicesResources from "../../images/services-and-resources.jpg";

//Icons
import iconPoc from "../../images/nav-icons/1-serviceicon-poc.svg";
import iconUx from "../../images/nav-icons/2-serviceicon-ux.svg";
import iconProduct from "../../images/nav-icons/3-serviceicon-product.svg";
import iconCompliance from "../../images/nav-icons/7-serviceicon-compliance.svg";
import iconDigital from "../../images/nav-icons/5-serviceicon-digital.svg";
import iconData from "../../images/nav-icons/4-serviceicon-data.svg";
import iconSoftware from "../../images/nav-icons/8-serviceicon-software.svg";
import iconResources from "../../images/nav-icons/11-serviceicon-resources.svg";
import iconSupport from "../../images/nav-icons/10-serviceicon-support.svg";
import iconTesting from "../../images/nav-icons/9-serviceicon-testing.svg";
import iconCloud from "../../images/nav-icons/6-serviceicon-cloud.svg";
import iconWso2 from "../../images/nav-icons/12-serviceicon-wso2.svg";
import iconFintech from "../../images/nav-icons/13-serviceicon-fintech.svg";
import iconAWS from "../../images/nav-icons/14-serviceicon-aws.svg";
import icondevops from "../../images/nav-icons/15-serviceicon-devops.svg";

//Process
import OurProcess from "../../components/services/OurProcess";

//Contact
import ConsultationBanner from "../../images/consultation.jpg";
import BookConsultation from "../../components/BookConsultation";
import GetInTouchbtn from "../../components/GetInTouchbtn";

const Services = (props) => (
  <Layout bodyClass="main-service-page page-services">
    <Seo
      title="Services"
      description="Software application development services and product innovation
                using sophisticated technology stacks are some of the core
                components of our business. Find out how we can support you in
                your software product lifecycle."
    />
    <IntroBanner
      title="Software Engineering Services"
      subtitle="Design and build for today, and tomorrow..."
      image={Banner}
    />
    <div className="container service-overview pb-6 section-2">
      <div className="row pt-6">
        <div className="col-12">
          <h2 className="title-1">Our Services</h2>
        </div>
      </div>
      <div className="row py-6">
        <div className="col-md-6 pr-md-5 order-md-2">
          <img alt="Strategy & Design" className="img-fluid" src={StrategyDesign} />
        </div>
        <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
          <h2 className="title-2">Strategy &amp; Design</h2>
          <p>
            Our experienced team can help you test the feasibility of your idea right from the start with Proof of Concept (PoC) and Minimum Viable Product (MVP) design and build services.
            <br/>
            We also have specialist UX and Technical Designers to ensure what we create for you is not just functional but optimised for engagement too. Ensure scalability and efficiency for both your short and long term needs with strategic platform architecture and design services from our experts.
          </p>
        </div>
      </div>
      <Row>
        <Col md={6} lg={4}>
          <a href="/services/strategy-design/poc-mvp">
          <Card className="process-card">
            <CardBody>
              <img alt="Service Icon" className="menu-item-icon" src={iconPoc} />
              <CardTitle tag="h5">
                Prototype &amp; Design from PoC to MVP
              </CardTitle>
              <CardText>
              We take you from ideation to delivery and beyond
              </CardText>
            </CardBody>
          </Card>
          </a>
        </Col>
        <Col md={6} lg={4}>
        <a href="/services/strategy-design/ux">
          <Card className="process-card">
            <CardBody>
              <img alt="Service Icon" className="menu-item-icon" src={iconUx} />
              <CardTitle tag="h5">
              UX &amp; Technical Design
              </CardTitle>
              <CardText>
              Our design experts know how to create for the now and the future
              </CardText>
            </CardBody>
          </Card>
          </a>
        </Col>
      </Row>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BuildEngineer} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Build &amp; Engineer</h2>
            <p>
              From new product development to digital transformation, we create bespoke solutions that solve real business problems. 
            </p>
            <p>
              Using the latest advancements in AI, ML & IoT, we work with you to integrate, connect and retrieve deep business insights buried deep in your data. 
            </p>
            <p>
              Improving efficiency and effectiveness is no longer reliant on new technology. We have a proven track record of streamlining existing systems and creating customised software solutions to transform how your business operates and ensure stringent compliance requirements are satisfied.
            </p>
          </div>
        </div>
        <Row>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/product-development">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconProduct} />
                <CardTitle tag="h5">
                Product Development
                </CardTitle>
                <CardText>
                If you dream it, we can build it
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          {/*<Col md={6} lg={4}>
          <a href="/services/build-engineer/compliance-services">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconCompliance} />
                <CardTitle tag="h5">
                Compliance Services
                </CardTitle>
                <CardText>
                Manage compliance obligations with confidence
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>*/}
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/digital-transformation">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconDigital} />
                <CardTitle tag="h5">
                Digital Transformation
                </CardTitle>
                <CardText>
                Unlock new business opportunities with transformational technological solutions
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/software-development">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconSoftware} />
                <CardTitle tag="h5">
                Customised Software Development
                </CardTitle>
                <CardText>
                Customise your solution to perfectly match your needs
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/ai">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconData} />
                <CardTitle tag="h5">
                Data Analytics & AI
                </CardTitle>
                <CardText>
                Unearth deep data insights with innovative AI solutions
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/quality-assurance">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconTesting} />
                <CardTitle tag="h5">
                Independent Validation & Quality Assurance
                </CardTitle>
                <CardText>
                Ensure your product is fit for purpose and comply with user expectations
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/salesforce">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconCloud} />
                <CardTitle tag="h5">
                Salesforce Development Services
                </CardTitle>
                <CardText>
                Salesforce development, integration and app exchange services
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/wso2-services">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconWso2} />
                <CardTitle tag="h5">
                WSO2 Services
                </CardTitle>
                <CardText>
                WSO2 implementation, CI/CD, analytics and support
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/aws">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconAWS} />
                <CardTitle tag="h5">
                AWS Solutions
                </CardTitle>
                <CardText>
                Unlocking the power of AWS for growth and optimisation
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          <Col md={6} lg={4}>
          <a href="/services/build-engineer/devops">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={icondevops} />
                <CardTitle tag="h5">
                DevOps Services
                </CardTitle>
                <CardText>
                Innovate faster, deploy smarter with DevOps
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col>
          {/* <Col md={6} lg={4}>
          <a href="/services/build-engineer/fintech">
            <Card className="process-card">
              <CardBody>
                <img alt="Service Icon" className="menu-item-icon" src={iconFintech} />
                <CardTitle tag="h5">
                Fintech Startup/Scaleup Services
                </CardTitle>
                <CardText>
                Your Trusted Tech Partner for Success
                </CardText>
              </CardBody>
            </Card>
            </a>
          </Col> */}
        </Row>
        <div className="row py-6">
        <div className="col-md-6 pr-md-5 order-md-2">
          <img alt="product innovation" className="img-fluid" src={ServicesResources} />
        </div>
        <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
          <h2 className="title-2">Managed Services &amp; Resources</h2>
          <p>
            Our teams have been trusted by hundreds of organisations to provide high quality managed IT support services and highly experienced resources to increase capacity as required.
          </p>
          <p>
            From Cloud Support Services, Application Monitoring & Maintenance Software Asset Management, Infrastructure & Utilities Software and Quality Assurance & Compliances, our people provide peace of mind and maximum uptime. 
          </p>
          <p>
            If you don't have the right resources available or need to scale quickly, we offer Dedicated Managed Teams, R&D as a Service, CTO as a Service and Business Analyst services.  
          </p>
        </div>
      </div>
      <Row>
        <Col md={6} lg={4}>
        <a href="/services/managed-services-resources/support-maintenance">
          <Card className="process-card">
            <CardBody>
              <img alt="Service Icon" className="menu-item-icon" src={iconSupport} />
              <CardTitle tag="h5">
              Support &amp; Maintenance
              </CardTitle>
              <CardText>
              Create capacity and efficiency in your business
              </CardText>
            </CardBody>
          </Card>
          </a>
        </Col>
        <Col md={6} lg={4}>
        <a href="/services/managed-services-resources/resources">
          <Card className="process-card">
            <CardBody>
              <img alt="Service Icon" className="menu-item-icon" src={iconResources} />
              <CardTitle tag="h5">
              Certified Professionals
              </CardTitle>
              <CardText>
              Extend your team with our team
              </CardText>
            </CardBody>
          </Card>
          </a>
        </Col>
      </Row>
    </div>

    <Container>
    <div className="getting-touch mb-6"><GetInTouchbtn textdce={'Free consultation'} /></div>
    
    </Container>
    <Container>
      <OurProcess />
    </Container>

    <Container className="my-6">
      <h2 className="title-1 mb-3">Book a free consultation</h2>
      <BookConsultation image={ConsultationBanner} />
    </Container>
    
  </Layout>
);

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "services-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Services;
